import { useState } from 'react';

export const EmailInput = ({
  onSubmit,
}: {
  onSubmit: (email: string) => void;
}) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>('');

  const onChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (error) {
      setError(null);
    }
    setEmail(e.target.value);
  };

  const onSubmitHandler = async () => {
    setLoading(true);
    try {
      await onSubmit(email);
    } catch (e) {
      setError((e as Error).message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>

      <div
        className="input-group"
        style={{ display: 'flex', height: '50px', position: 'relative' }}
      >
        <input
          type="email"
          value={email}
          onChange={onChangeHandler}
          placeholder="Your E-mail Address"
          disabled={loading}
          style={{
            border: 'none',
            height: '50px',
            fontSize: '15px',
            width: '300px',
            padding: '10px',
            paddingRight: '80px',
            pointerEvents: 'auto',
            WebkitAppearance: 'none',
            outline: 'none',
            borderRadius: 8,
            fontFamily: "'Barlow Semi Condensed', sans-serif",
          }}
        />
        <input
          type="button"
          style={{
            border: 'none',
            width: '80px',
            height: '100%',
            pointerEvents: 'auto',
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            backgroundColor: '#B2579C',
            color: 'white',
            fontFamily: "'Barlow Semi Condensed', sans-serif",
            cursor: 'pointer',
            fontSize: '15px',
            position: 'absolute',
            right: 0,
          }}
          onClick={onSubmitHandler}
          value="Send"
        />
      </div>
      {error ? (
        <span
          style={{
            display: 'flex',
            alignSelf: 'center',
            color: 'red',
            fontFamily: "'Barlow Semi Condensed', sans-serif",
          }}
        >
          {error}
        </span>
      ) : null}
    </div>
  );
};
