import WebAppLogo from '../assets/web-app_logo.jpeg';

const webAppUrl = 'https://www.veroxai.io/';

export const WebAppButton = () => (
  <a href={webAppUrl} className="badge badge-web">
    <img src={WebAppLogo} alt="Get Web App" />
    VISIT WEB APP
  </a>
);
