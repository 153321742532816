import { Digit } from './Digit';

function daysInThisMonth() {
  var now = new Date();
  return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
}

export const Countdown = ({
  timeLeftInSeconds,
}: {
  timeLeftInSeconds: number;
}) => {
  const daysInMonth = daysInThisMonth();
  const days = Math.floor(timeLeftInSeconds / (60 * 60 * 24)); // 22
  const hours = Math.floor(timeLeftInSeconds / (60 * 60)) % 24; // 24
  const minutes = Math.floor((timeLeftInSeconds / 60) % 60); // 60
  const seconds = Math.floor(timeLeftInSeconds % 60); // 60

  return (
    <div className="countdown">
      <Digit label="days" currentValue={days} maxValue={daysInMonth} />
      <Digit label="hours" currentValue={hours} maxValue={24} />
      <Digit label="minutes" currentValue={minutes} maxValue={60} />
      <Digit label="seconds" currentValue={seconds} maxValue={60} />
    </div>
  );
};
