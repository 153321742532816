import './App.css';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { loadFull } from 'tsparticles';
import {
  Container,
  Engine,
  IOptions,
  RecursivePartial,
} from 'tsparticles-engine';
import Particles from 'react-tsparticles';
import particleOptions from './assets/particles.json';
import { VeroxLogo } from './components/VeroxLogo';
import { Countdown } from './components/Countdown';
import { EmailInput } from './components/EmailInput';
import { apiClient } from './apiClient';
import { ResponseError } from '@kadima-tech/verox-countdown-sdk';
import { WebAppButton } from './components/WebAppButton';

enum CountdownType {
  demo,
  main,
}

const seconds = (date: Date) => date.getTime() / 1000;
const countdownType = CountdownType.main;

function App() {
  const [emailSubmitted, setEmailSubmitted] = useState(false);

  const particlesInit = useCallback(async (engine: Engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container?: Container) => {}, []);

  const startDate = useMemo(
    () => new Date(Date.UTC(2024, 4, 4, 12, 0, 0, 0)),
    []
  );
  const endDate = useMemo(
    () => new Date(Date.UTC(2024, 4, 10, 18, 0, 0, 0)),
    []
  );
  const totalTime = seconds(endDate) - seconds(startDate);

  const [glowLevel, setGlowLevel] = useState('0');
  const [timeLeft, setTimeLeft] = useState(
    endDate.getTime() - new Date().getTime()
  );

  useEffect(() => {
    const now = new Date();
    setInterval(() => {
      setTimeLeft(endDate.getTime() - new Date().getTime());
      setGlowLevel(
        ((totalTime - (seconds(endDate) - seconds(now))) / totalTime).toFixed(1)
      );
    }, 1000);
  }, [startDate, endDate, totalTime]);

  const countdownFinishedElement =
    countdownType === CountdownType.main ? (
      <>
        <div className="pre-text">VeroxAI's mobile apps have been released</div>
        <WebAppButton />
      </>
    ) : (
      <div className="pre-text">Verox AI</div>
    );

  const onSubmitEmail = async (email: string) => {
    if (!email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
      throw new Error('Please enter a valid e-mail address');
    }

    try {
      await apiClient.api.getNotified({
        getNotifiedBody: {
          email,
        },
      });
      setEmailSubmitted(true);
    } catch (e) {
      if ((e as ResponseError).name === 'ResponseError') {
        const { response } = e as ResponseError;

        if (response.status >= 400 && response.status < 500) {
          throw new Error('Please enter a valid e-mail address');
        }
      }

      throw new Error('An unknown error occurred. Please try again later');
    }
  };

  return (
    <>
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={particleOptions as RecursivePartial<IOptions>}
      />

      <svg
        width="0"
        height="0"
        className="gradient"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#d0478f" />
            <stop offset="100%" stopColor="#43add9" />
          </linearGradient>
        </defs>
      </svg>

      <section
        className="countdown-wrapper"
        style={{
          pointerEvents: 'none',
        }}
      >
        <VeroxLogo glowLevel={glowLevel} />

        {new Date() <= endDate ? (
          <>
            <div className="pre-text">
              VeroxAI's mobile apps will launch in:
            </div>
            <Countdown timeLeftInSeconds={timeLeft / 1000} />
            {!emailSubmitted ? (
              <>
                <div className="pre-text pre-text--small">
                  Notify me when it's ready:
                </div>
                <EmailInput onSubmit={onSubmitEmail} />
              </>
            ) : (
              <div className="pre-text pre-text--small">
                You have been added to our list and will be notified once we
                launch
              </div>
            )}
          </>
        ) : (
          countdownFinishedElement
        )}
      </section>
    </>
  );
}

export default App;
