import Logo from '../assets/verox-logo.png';
import LogoGlow from '../assets/verox-logo_glow.png';

export const VeroxLogo = ({ glowLevel }: { glowLevel: string }) => (
  <div className="logo-wrapper">
    <img
      src={LogoGlow}
      className="glow"
      style={{ opacity: glowLevel }}
      alt="Glowing Eye"
    />
    <img src={Logo} className="logo" alt="Verox Logo" />
  </div>
);
