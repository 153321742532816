import { useCallback, useEffect, useState } from 'react';

export const Digit = ({
  label,
  currentValue,
  maxValue,
}: {
  label: string;
  currentValue: number;
  maxValue: number;
}) => {
  const [placeholderDigit, setPlaceholderDigit] = useState<number | null>(
    Math.floor(Math.random() * 100)
  );
  const [progress, setProgress] = useState(0);
  const [circumference, setCircumference] = useState(0);
  const [firstRender] = useState(Date.now());
  const [rect, setRect] = useState<DOMRect | null>(null);

  const callBackRef = useCallback<(node: SVGCircleElement | null) => void>(
    (domNode) => {
      function setBoundingRect() {
        if (domNode) {
          setRect(domNode.getBoundingClientRect());
        }
      }

      setBoundingRect();
      window.addEventListener('resize', () =>
        setTimeout(setBoundingRect, 1000)
      );
    },
    []
  );

  useEffect(() => {
    if (rect) {
      const radius = rect.width / 2;
      const circumference = Math.round(2 * Math.PI * radius);
      setCircumference(circumference);
    }
  }, [rect]);

  useEffect(() => {
    setProgress((1 - currentValue / maxValue) * circumference);
  }, [circumference, currentValue, maxValue]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (Date.now() - firstRender < 1000) {
        setPlaceholderDigit(Math.floor(Math.random() * 100));
      } else {
        setPlaceholderDigit(null);
        clearInterval(interval);
      }
    }, 50);
  }, [firstRender]);

  return (
    <div className="">
      <div className="item html">
        <div className="text">
          <span className="digits">
            {String(placeholderDigit ?? currentValue).padStart(2, '0')}
          </span>
          <span>{label}</span>
        </div>
        <svg
          className="circle"
          width="220"
          height="220"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <circle
              id="circle"
              ref={callBackRef}
              r="100.26761"
              cy="110"
              cx="110"
              strokeWidth="2"
              stroke="#fff"
              fill="none"
            />
            <circle
              style={{
                strokeDashoffset: progress,
              }}
              id="circle"
              className="circle_animation"
              r="100.26761"
              cy="110"
              cx="110"
              strokeWidth="6"
              stroke="url(#gradient)"
              fill="none"
            />
          </g>
        </svg>
      </div>
    </div>
  );
};
